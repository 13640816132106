import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Hawthorne({ styles, elements, ...props }) {
  const Elements = useComponents()
  const customFieldCount = elements.custom.length
  const stackedDisplay = customFieldCount > 0
  const backgroundColor = styles.forElement("page").backgroundColor

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-container">
        <div className="formkit-content-container">
          <Elements.Heading name="name" defaults={{ content: "Name" }} />
          <div>
            <div className="formkit-card" style={{ backgroundColor }}>
              <Elements.Heading
                name="header"
                defaults={{ content: "Enter a catchy page headline" }}
              />
              <Elements.Region name="content" className="formkit-content">
                <Elements.Content
                  defaults={{
                    content: "Provide some more detail in this subtitle"
                  }}
                />
              </Elements.Region>
            </div>
            <Elements.Form style={styles.forElement("form")}>
              <Elements.Errors />
              <Elements.CustomFields data-stacked={stackedDisplay}>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Subscribe" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
            <Elements.Content
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at anytime."
              }}
            />
          </div>
          <Elements.BuiltWith background="page" />
          {/* This empty div helps center elements (via flex). */}
          <div />
        </div>
        <div
          className="formkit-background"
          style={{
            backgroundColor: styles.forElement("background").backgroundColor
          }}
        />
        <div
          className="formkit-background"
          style={styles.forElement("background")}
        />
      </div>
    </Elements.LandingPage>
  )
}

Hawthorne.style = "landing_page"
Hawthorne.categories = ["Newsletter"]
Hawthorne.thumbnail = ""
Hawthorne.preview = "https://pages.convertkit.com/23e999071c/eb41389962"
Hawthorne.fields = [
  {
    name: "email_address",
    label: "Enter your email address"
  }
]

export default createTemplate(Hawthorne, { name: "Hawthorne" })
